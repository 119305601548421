const fullSearch = [
    "exactMatch",

    "startsWith",
    "contains",
    "endsWith",

    "notExactMatch",

    "startsNotWith",
    "containsNot",
    "endsNotWith",

    "selections_tlds"
];

const availability = [
    "selections"
]

const numberRange = [
    "min",
    "max"
]

const dateRange = [
    "dateRange",
]

const timeLeft = [
    "timeLeft"
]

export default {
    fullSearch,
    availability,
    numberRange,
    dateRange,
    timeLeft,
}

