import { useState } from "react";
import Datepicker from "../datepicker/Datepicker";
import Select from "../select/Select";
import TextInput from "../textInput/TextInput";
import functions from "../../helpers/functions";
import TimeLeftInput from "../timeLeftInput/TimeLeftInput";

function filterListSelections({
    inputValue = {},
    placeholder,
    columnId,
    setFilter,
    options,
    propertyName,
}) {
    let newInputFormat = [];
    for (let property in inputValue) {
        if (inputValue[property]) {
            newInputFormat.push({
                label: property,
                value: property,
            });
        }
    }
    return (
        <Select
            isParentControl
            selectedOption={newInputFormat}
            options={options}
            onChange={(selections) => {
                let obj = {};
                options.map((o) => {
                    obj[o.label] = false;
                });
                if (selections?.length > 0) {
                    selections.map((o) => {
                        obj[o.label] = o.value;
                    });
                }
        
                setFilter(columnId, { [propertyName]: obj });
            }}
            placeholder={placeholder}
        />
    );
}

function filterDateRange({ placeholder, columnId, setFilter, options }) {
    return <Datepicker isLabelHidden />;
}

function filterTimeLeft({
    inputValue,
    disallowedTimes,
    columnId,
    setFilter,
    propertyName,
}) {
    return (
        <TimeLeftInput
            isParentControl
            value={inputValue}
            onChange={(times) => {
                setFilter(columnId, {
                    [propertyName]: times,
                });
            }}
            typesDisallowed={disallowedTimes}
        />
    );
}

function filterTextInput({
    type,
    columnId,
    setFilter,
    placeholder,
    propertyName,
    inputValue,
}) {
    return (
        <TextInput
            isInfoIconVisible
            type={type}
            value={inputValue}
            placeholder={placeholder}
            onChange={(newValue) => {
                setFilter(columnId, {
                    [propertyName]: newValue,
                });
            }}
            tooltipContent={
                <span>
                    Multiple keywords seperated by commas.
                    <br />
                    <br />
                    Patterns:
                    <br />
                    C - Consonant
                    <br />
                    V - Vowel
                    <br />
                    L - Letter (C or V)
                    <br />
                    N - Number
                    <br />
                    D - Any Character
                    <br />
                    <br />
                    Examples:
                    <br />
                    domain,name,short
                    <br />
                    CVCViC,CVCe,ChVCC
                    <br />
                    short-55
                    <br />
                    DDLLL-N5
                    <br />
                </span>
            }
        />
    );
}

const startsWith = (obj) =>
    filterTextInput({
        ...obj,
        placeholder: "Starts with",
        propertyName: "startsWith",
    });
const contains = (obj) =>
    filterTextInput({
        ...obj,
        placeholder: "Contains",
        propertyName: "contains",
    });
const endsWith = (obj) =>
    filterTextInput({
        ...obj,
        placeholder: "Ends with",
        propertyName: "endsWith",
    });

const startsNotWith = (obj) =>
    filterTextInput({
        ...obj,
        placeholder: "Starts Not with",
        propertyName: "startsNotWith",
    });
const containsNot = (obj) =>
    filterTextInput({
        ...obj,
        placeholder: "Contains Not",
        propertyName: "containsNot",
    });
const endsNotWith = (obj) =>
    filterTextInput({
        ...obj,
        placeholder: "Ends Not with",
        propertyName: "endsNotWith",
    });

const exactMatch = (obj) =>
    filterTextInput({
        ...obj,
        placeholder: "Exact Match",
        propertyName: "exactMatch",
    });
const notExactMatch = (obj) =>
    filterTextInput({
        ...obj,
        placeholder: "Not Exact Match",
        propertyName: "notExactMatch",
    });

const selections = (obj) =>
    filterListSelections({
        ...obj,
        placeholder: obj.selectionPlaceholder,
        propertyName: "selections",
    });
const selections_tlds = (obj) =>
    filterListSelections({
        ...obj,
        placeholder: "TLDs",
        propertyName: "selections_tlds",
    });

const min = (obj) =>
    filterTextInput({
        ...obj,
        type: "number",
        placeholder: "Min",
        propertyName: "min",
    });
const max = (obj) =>
    filterTextInput({
        ...obj,
        type: "number",
        placeholder: "Max",
        propertyName: "max",
    });

const dateRange = filterDateRange;

const timeLeft = (obj) =>
    filterTimeLeft({
        ...obj,
        propertyName: "timeLeft",
    });

export default {
    exactMatch,
    notExactMatch,

    startsWith,
    contains,
    endsWith,

    startsNotWith,
    containsNot,
    endsNotWith,

    selections,
    selections_tlds,

    dateRange,
    timeLeft,

    min,
    max,
};
