import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import styles from "./Datepicker.module.scss";
import 'react-datepicker/dist/react-datepicker.css';

export default function Datepicker({
    isLabelHidden
}) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handlePredefinedOption = (option) => {
    const now = new Date();
    let start;
    switch (option) {
      case 'past 12 hours':
        start = new Date(now.getTime() - 12 * 60 * 60 * 1000); // 12 hours ago
        break;
      case 'past 1 day':
        start = new Date(now.getTime() - 24 * 60 * 60 * 1000); // 1 day ago
        break;
      case 'past 2 days':
        start = new Date(now.getTime() - 48 * 60 * 60 * 1000); // 2 days ago
        break;
      case 'past 1 week':
        start = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // 1 week ago
        break;
      default:
        start = now;
    }
    setStartDate(start);
    setEndDate(now);
  };

  return (
    <div>
      {isLabelHidden?"":(<span>Select Date Range</span>)}
      <div>
        <button onClick={() => handlePredefinedOption('past 12 hours')}>Past 12 Hours</button>
        <button onClick={() => handlePredefinedOption('past 1 day')}>Past 1 Day</button>
        <button onClick={() => handlePredefinedOption('past 2 days')}>Past 2 Days</button>
        <button onClick={() => handlePredefinedOption('past 1 week')}>Past 1 Week</button>
      </div>
      <div style={{ marginTop: '20px' }}>
        <DatePicker 
          selected={startDate} 
          onChange={(date) => setStartDate(date)} 
          selectsStart 
          startDate={startDate} 
          endDate={endDate} 
          placeholderText="Start Date" 
          showTimeSelect
          dateFormat="Pp"
        />
        <span> to </span>
        <DatePicker 
          selected={endDate} 
          onChange={(date) => setEndDate(date)} 
          selectsEnd 
          startDate={startDate} 
          endDate={endDate} 
          placeholderText="End Date" 
          showTimeSelect
          dateFormat="Pp"
        />
      </div>
    </div>
  );
};