import { useEffect, useState } from "react";
import styles from "./useTableFilters.module.scss";
import functions from "../../helpers/functions";
import downloadIcon from "../../images/download-icon.svg";
import Checkbox from "../checkbox/Checkbox";
import filterFunctions from "./filterFunctions";
import matchingComponentNames from "./matchingComponentNames";
import filterComponents from "./filterComponents";
import QuickView from "../quickView/QuickView";
import reactToString from "react-to-string";

export default function useTableFilters({ columns = [], data }) {
    // [
    //     {
    //         "id": "",
    //         "value":""
    //     },
    //     ...
    // ]
    const [filters, setFilters] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [uniqueRowValues, setUniqueRowValues] = useState([]);

    useEffect(() => {
        let uniqueData = {};
        data.map((o) => {
            Object.keys(o).map((key) => {
                if (!uniqueData[key]) uniqueData[key] = {};
                uniqueData[key][o[key]] = 1;
            });
        });
        setUniqueRowValues(uniqueData);
    }, [data]);

    // Match the filter functions with their function name from the columns
    let filterMatchingComponents = [];
    columns
        .filter((o) => o?.filterFn)
        .map((o) => {
            let columnId = o.accessorKey;
            let header = o.header;
            let filterName = o.filterFn;
            let extraProps = o.extraProps?.filters;
            extraProps = extraProps ? extraProps : {};

            filterMatchingComponents.push({
                filterName,
                func: (row, columnId, value) => {
                    return filterFunctions[filterName]({
                        row,
                        columnId,
                        value,
                    });
                },
                columnId,
                header,
                componentNames: matchingComponentNames[filterName],
                ...extraProps,
            });
        });

    // converting to table's format condition
    let filterFns = {};
    filterMatchingComponents.map((o) => {
        let { filterName, func } = o;
        filterFns[filterName] = func;
    });

    // get components for each component names in function name
    let components = [];
    filterMatchingComponents.map((o) => {
        let { columnId, header, componentNames } = o;

        components.push({
            header: header(),
            componentList: componentNames.map((componentName, idx) => {
                let isTLD = columnId == "sld" || columnId == "domain";

                return (
                    <div key={idx}>
                        {filterComponents[componentName]({
                            setFilter: setColumnFilters,
                            options: uniqueRowValues[columnId]
                                ? Object.keys(
                                      isTLD
                                          ? uniqueRowValues["tld"]
                                          : uniqueRowValues[columnId]
                                  ).map((value) => ({
                                      label: value,
                                      value,
                                  }))
                                : undefined,
                            selectionPlaceholder: header(),
                            inputValue: filters.find((o) => o.id == columnId)
                                ?.value?.[componentName],
                            ...o,
                        })}
                    </div>
                );
            }),
        });
    });

    components = (
        <QuickView
            header={"Filters"}
            body={
                components &&
                components.map((o, idx) => {
                    let header = o.header;
                    let inputs = o.componentList;

                    return (
                        <div className={styles["filter-group"]} key={idx}>
                            <span className={styles.header}>{header}</span>
                            <div className={styles["filter-contianer"]}>
                                {inputs}
                            </div>
                        </div>
                    );
                })
            }
            onClear={() => {
                setFilters([]);
                setAppliedFilters([]);
            }}
            onApply={() => {
                setAppliedFilters(filters);
            }}
            isLoadingOnApply
            customTrigger={<span className={styles["trigger"]}>+ Filters</span>}
            sideTriggerDisplay={
                <div className={styles["all-chips-container"]}>
                    {/* Chips */}
                    <div className={styles["chips-wrapper"]}>
                        {appliedFilters.flatMap((o, idx) => {
                            let columnHeader = columns
                                .find((o1) => o1.accessorKey == o.id)
                                .header();
                            columnHeader = reactToString(columnHeader);

                            let components = [];
                            let counter = 0;
                            let totalInputsInGroup = Object.keys(
                                o.value
                            ).length;
                            for (let type in o.value) {
                                let typeDisplay = "";
                                switch (type) {
                                    case "exactMatch":
                                        typeDisplay = "Exact Match";
                                        break;
                                    case "startsWith":
                                        typeDisplay = "Starts With";
                                        break;
                                    case "contains":
                                        typeDisplay = "Contains";
                                        break;
                                    case "endsWith":
                                        typeDisplay = "Ends With";
                                        break;
                                    case "notExactMatch":
                                        typeDisplay = "Not Exact Match";
                                        break;
                                    case "startsNotWith":
                                        typeDisplay = "Starts Not With";
                                        break;
                                    case "containsNot":
                                        typeDisplay = "Contains Not";
                                        break;
                                    case "endsNotWith":
                                        typeDisplay = "Ends Not With";
                                        break;
                                    case "selections_tlds":
                                        typeDisplay = "TLDs";
                                        break;
                                    case "selections":
                                        typeDisplay = "Selections";
                                        break;
                                    case "min":
                                        typeDisplay = "Min";
                                        break;
                                    case "max":
                                        typeDisplay = "Max";
                                        break;
                                    case "dateRange":
                                        typeDisplay = "Date Range";
                                        break;
                                    case "timeLeft":
                                        typeDisplay = "Time Left";
                                        break;
                                    default:
                                        typeDisplay =
                                            type.charAt(0).toUpperCase() +
                                            type.substring(1);
                                }
                                let isTextDisplaySameAsHeader =
                                    columnHeader == typeDisplay;

                                let values = o.value[type];
                                let value = values;
                                if (
                                    typeof values == "object" &&
                                    type == "selections_tlds"
                                ) {
                                    // combine all the values in the object
                                    let string = "";
                                    for (let key in values) {
                                        // select only selected once which is "true"
                                        if (values[key]) {
                                            if (string == "") string = key;
                                            else string += ", " + key;
                                        }
                                    }

                                    value = string;
                                } else if (
                                    typeof values == "object" &&
                                    type == "timeLeft"
                                ) {
                                    let descOrder = [
                                        "years",
                                        "months",
                                        "weeks",
                                        "days",
                                        "hours",
                                        "minutes",
                                    ];

                                    let string = "";
                                    descOrder.map((type) => {
                                        if (values?.[type]) {
                                            let value = `${values[type]} ${type}`;

                                            if (string == "") string = value;
                                            else string += ", " + value;
                                        }
                                    });
                                    value = string;
                                } else if (
                                    typeof values == "object" &&
                                    type == "selections"
                                ) {
                                    value = Object.keys(value)
                                        .filter((key) => value[key])
                                        .join(", ");
                                }

                                if (value) {
                                    components.push(
                                        <div
                                            key={counter}
                                            className={styles["chip"]}
                                            onClick={() => {
                                                // find matched position
                                                let newFilters = JSON.parse(
                                                    JSON.stringify(
                                                        appliedFilters
                                                    )
                                                );
                                                let matchedPos =
                                                    newFilters.findIndex(
                                                        (o1) => o1.id == o.id
                                                    );

                                                if (matchedPos >= 0) {
                                                    if (
                                                        Object.keys(
                                                            newFilters[
                                                                matchedPos
                                                            ].value
                                                        ).length == 1
                                                    ) {
                                                        // remove entire element
                                                        newFilters.splice(
                                                            matchedPos,
                                                            1
                                                        );
                                                    } else {
                                                        delete newFilters[
                                                            matchedPos
                                                        ].value[type];
                                                    }

                                                    setFilters(newFilters);
                                                    setAppliedFilters(
                                                        newFilters
                                                    );
                                                }
                                            }}
                                        >
                                            {` ${
                                                !isTextDisplaySameAsHeader &&
                                                type != "timeLeft"
                                                    ? `${typeDisplay}: `
                                                    : ""
                                            }${value}`}
                                            <span
                                                className={styles["chip-close"]}
                                            >
                                                X
                                            </span>
                                        </div>
                                    );
                                }

                                counter++;
                            }

                            if (components.length == 0) return "";

                            return (
                                <div
                                    key={idx}
                                    className={styles["chip-container"]}
                                >
                                    <div className={styles["chip-header"]}>
                                        {columnHeader}:
                                    </div>

                                    <div className={styles["chip-group"]}>
                                        {components}
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {/* Clear */}
                    {appliedFilters.length > 0 && (
                        <span
                            className={styles["clear"]}
                            onClick={() => {
                                setAppliedFilters([]);
                                setFilters([]);
                            }}
                        >
                            Clear
                        </span>
                    )}
                </div>
            }
        />
    );

    function setColumnFilters(id, value = {}) {
        // turn to object
        let filterObj = {};
        filters.map((o) => (filterObj[o.id] = o.value));

        if (!filterObj[id]) filterObj[id] = {};
        filterObj[id] = {
            ...filterObj[id],
            ...value,
        };

        setFilters(
            Object.keys(filterObj).map((id) => ({ id, value: filterObj[id] }))
        );
    }

    let exportButton = (filteredRows, headers) => {
        let rows = [headers.map((o) => o.header).join(",")];
        filteredRows.map((o) => {
            let originalData = o.original;

            // add the first layer
            rows.push(
                headers
                    .map((o1) =>
                        o1.id == "domain"
                            ? originalData[o1.id] + originalData["tld"]
                            : originalData[o1.id]
                    )
                    .join(",")
            );

            // add the subrows
            originalData?.subRows?.map((o) => {
                if (!o?.subrowHeader) {
                    rows.push(headers.map((o1) => o[o1.id]).join(","));
                }
            });
        });

        return (
            <div
                className={styles["export-button"]}
                onClick={() =>
                    functions.handleExportCSV({
                        filteredRows: rows,
                        filename: "Domains",
                    })
                }
            >
                <img src={downloadIcon} />
                Export
            </div>
        );
    };

    return {
        columnFilters: appliedFilters,
        setColumnFilters,
        exportButton,
        components,
        filterFns,
    };
}
