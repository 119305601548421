export default function PrivacyPolicyChromeExtension() {
    return (
        <div style={{ padding: "40px"}}>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                <strong>
                    Privacy Policy for Domain Appraisal Tool Central Chrome
                    Extension
                </strong>
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                This Privacy Policy outlines how Domain Appraisal Tool Central
                ("we," "our," or "us") collects, uses, and shares information
                when you use our Chrome extension. By using our extension, you
                agree to the collection and use of information in accordance
                with this policy.
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                1. Information We Collect
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                We collect the following types of information:
            </p>
            <ul style={{ marginBottom: "0cm", marginTop: "0cm" }} type="disc">
                <li
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "8.0pt",
                        marginLeft: "0cm",
                        fontSize: "11.0pt",
                        fontFamily: '"Calibri",sans-serif',
                    }}
                >
                    <strong>User Data:</strong> We may collect personal
                    information such as your username, email address, and any
                    other data you provide when you log into our services or
                    interact with our extension.
                </li>
                <li
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "8.0pt",
                        marginLeft: "0cm",
                        fontSize: "11.0pt",
                        fontFamily: '"Calibri",sans-serif',
                    }}
                >
                    <strong>Cookies and Tracking Technologies:</strong> We may
                    use cookies and similar tracking technologies to monitor
                    activity on our extension and store certain information.
                </li>
            </ul>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                2. How We Use Your Information
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                We use the collected information for various purposes,
                including:
            </p>
            <ul style={{ marginBottom: "0cm", marginTop: "0cm" }} type="disc">
                <li
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "8.0pt",
                        marginLeft: "0cm",
                        fontSize: "11.0pt",
                        fontFamily: '"Calibri",sans-serif',
                    }}
                >
                    To provide and maintain our service.
                </li>
                <li
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "8.0pt",
                        marginLeft: "0cm",
                        fontSize: "11.0pt",
                        fontFamily: '"Calibri",sans-serif',
                    }}
                >
                    To notify you about changes to our service.
                </li>
                <li
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "8.0pt",
                        marginLeft: "0cm",
                        fontSize: "11.0pt",
                        fontFamily: '"Calibri",sans-serif',
                    }}
                >
                    To allow you to participate in interactive features of our
                    service when you choose to do so.
                </li>
                <li
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "8.0pt",
                        marginLeft: "0cm",
                        fontSize: "11.0pt",
                        fontFamily: '"Calibri",sans-serif',
                    }}
                >
                    To provide customer support.
                </li>
                <li
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "8.0pt",
                        marginLeft: "0cm",
                        fontSize: "11.0pt",
                        fontFamily: '"Calibri",sans-serif',
                    }}
                >
                    To gather analysis or valuable information so that we can
                    improve our service.
                </li>
                <li
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "8.0pt",
                        marginLeft: "0cm",
                        fontSize: "11.0pt",
                        fontFamily: '"Calibri",sans-serif',
                    }}
                >
                    To monitor the usage of our service.
                </li>
                <li
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "8.0pt",
                        marginLeft: "0cm",
                        fontSize: "11.0pt",
                        fontFamily: '"Calibri",sans-serif',
                    }}
                >
                    To detect, prevent, and address technical issues.
                </li>
            </ul>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                3. Sharing Your Information
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                We do not share your personal information with any third-party
                service providers. All user data is securely stored in our
                database and is only used to enhance the interactivity of our
                features. We are committed to ensuring that your data remains
                private and is not disclosed to any external parties.
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                4. User Data Retention
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                We retain your information only for as long as necessary to
                fulfill the purposes for which it was collected, including for
                the purposes of satisfying any legal, accounting, or reporting
                requirements.
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                5. Security of Your Information
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                We take the security of your personal data seriously and
                implement reasonable measures to protect it from unauthorized
                access, use, or disclosure. However, please be aware that no
                method of transmission over the internet or method of electronic
                storage is 100% secure.
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                6. Your Rights
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                Depending on your location, you may have certain rights
                regarding your personal information, including:
            </p>
            <ul style={{ marginBottom: "0cm", marginTop: "0cm" }} type="disc">
                <li
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "8.0pt",
                        marginLeft: "0cm",
                        fontSize: "11.0pt",
                        fontFamily: '"Calibri",sans-serif',
                    }}
                >
                    The right to access, update, or delete the information we
                    have on you.
                </li>
                <li
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "8.0pt",
                        marginLeft: "0cm",
                        fontSize: "11.0pt",
                        fontFamily: '"Calibri",sans-serif',
                    }}
                >
                    The right to request that we restrict the processing of your
                    personal information.
                </li>
                <li
                    style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: "8.0pt",
                        marginLeft: "0cm",
                        fontSize: "11.0pt",
                        fontFamily: '"Calibri",sans-serif',
                    }}
                >
                    The right to withdraw consent at any time where we rely on
                    your consent to process your personal information.
                </li>
            </ul>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                7. Changes to This Privacy Policy
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page and updating the effective date at the top. You are
                advised to review this Privacy Policy periodically for any
                changes.
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                8. International Data Transfers
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                If you are located outside of the United States and choose to
                provide information to us, please note that we transfer the
                data, including personal data, to the United States and process
                it there. Your consent to this Privacy Policy followed by your
                submission of such information represents your agreement to that
                transfer.
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                9. Children's Privacy
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                Our service does not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                anyone under the age of 13. If you are a parent or guardian and
                you are aware that your child has provided us with Personal
                Data, please contact us. If we become aware that we have
                collected Personal Data from children without verification of
                parental consent, we take steps to remove that information from
                our servers.
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                10. Links to Other Sites
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                Our service may contain links to other sites that are not
                operated by us. If you click on a third-party link, you will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy and terms of service of every site you
                visit. We have no control over and assume no responsibility for
                the content, privacy policies, or practices of any third-party
                sites or services.
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                11. Contact Us
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                If you have any questions about this Privacy Policy, please
                contact us at domainreseller64@gmail.com.
            </p>
            <p
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                }}
            >
                &nbsp;
            </p>
        </div>
    );
}
