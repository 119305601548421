import React, { useEffect, useState } from "react";
import styles from "./App.module.scss";
import logo from "./images/logo-300.svg";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import DomainAppraisal from "./pages/DomainAppraisal";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import chromeIcon from "./images/chrome-icon.svg";
import PrivacyPolicyChromeExtension from "./pages/privaryPolicy/PrivacyPolicyChromeExtension";
import SavAuction from "./pages/savAuction/SavAuction";
import functions from "./helpers/functions";
import Select from "./components/select/Select";
import constants from "./helpers/constants";
import Dynadot from "./pages/dynadot/Dynadot";
import dbFunc from "./helpers/dbFunc";

function App() {
    let isExtensionAppraisalPage =
        functions.getUrlPath() == "/domain-appraisal?type=extension";

    return (
        <div className="App">
            <Router>
                <nav className={styles.nav}>
                    <img
                        className={styles.logo}
                        src={
                            "https://i.ibb.co/XFh0Lb2/logo-300-removebg-preview.png"
                        }
                        onClick={() => {
                            functions.triggerGAEvent({
                                action: "topNav_logo",
                            });
                            window.open("/", "_self");
                        }}
                    />

                    <div className={styles["right-container"]}>
                        {/* <Select
                            classNames={{
                                control: styles["marketplace"],
                                ["dropdown-indicator"]:
                                    styles["marketplace-dropdown-indicator"],
                                valueContainer: styles["value-container"],
                                menuList: styles["menu-list"],
                                menu: styles["menu"],
                                indicatorSeparator:
                                    styles["indicator-separator"],
                                singleValue: styles["single-value"],
                            }}
                            isNotMulti
                            isNotSearchable
                            isFirstOptionUsedAsLabel
                            options={[
                                {
                                    label: "Marketplace Domains",
                                    value: "Marketplace Domains",
                                },
                                ...constants.marketplaces,
                            ]}
                            selectedOption={{
                                label: "Marketplace Domains",
                                value: "Marketplace Domains",
                            }}
                            onChange={(value) => {
                                let path = value.value;
                                functions.navigateToPath(path);
                            }}
                        /> */}

                        {!isExtensionAppraisalPage && (
                            <Link
                                className={styles["action-button"]}
                                to={process.env.REACT_APP_STORE_URL}
                                onClick={() => {
                                    functions.triggerGAEvent({
                                        action: "topNav_install",
                                    });
                                }}
                                children={
                                    <>
                                        <img src={chromeIcon} />
                                        {functions.getUrlPath() == "/"
                                            ? `Install Now`
                                            : "Install Extension"}
                                    </>
                                }
                            />
                        )}
                    </div>
                </nav>

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                        path="/domain-appraisal"
                        element={<DomainAppraisal />}
                    />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/privacy-policy-chrome-extension"
                        element={<PrivacyPolicyChromeExtension />}
                    />

                    <Route path="/domains/sav" element={<SavAuction />} />
                    <Route path="/domains/dynadot" element={<Dynadot />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
