const marketplaces = [
    {
        label: "Sav",
        value: "/domains/sav"
    },
    {
        label: "Dynadot",
        value: "/domains/dynadot"
    },
    // {
    //     label: "DropCatch",
    //     value: "/domains/dropcatch"
    // },
    // {
    //     label: "Catched",
    //     value: "/domains/catched"
    // },
    // {
    //     label: "Sedo",
    //     value: "/domains/sedo"
    // },
    // {
    //     label: "NameCheap",
    //     value: "/domains/namecheap"
    // },
    // {
    //     label: "GoDaddy",
    //     value: "/domains/godaddy"
    // },
    // {
    //     label: "Seo.Domains",
    //     value: "/domains/seodomains"
    // },
    // {
    //     label: "Name.com",
    //     value: "/domains/name"
    // },
    // {
    //     label: "DomainMarket",
    //     value: "/domains/domainmarket"
    // },
    // {
    //     label: "Flippa",
    //     value: "/domains/flippa"
    // },
    // {
    //     label: "Epik",
    //     value: "/domains/epik"
    // },
    // {
    //     label: "NameJet",
    //     value: "/domains/namejet"
    // },
    // {
    //     label: "SnapNames",
    //     value: "/domains/snapnames"
    // },
    // {
    //     label: "NicSell",
    //     value: "/domains/nicsell"
    // },
    // {
    //     label: "NameSilo",
    //     value: "/domains/namesilo"
    // },
    // {
    //     label: "DomainLore",
    //     value: "/domains/domainlore"
    // },
    // {
    //     label: "Ebay",
    //     value: "/domains/ebay"
    // },
    // {
    //     label: "Park.io",
    //     value: "/domains/parkio"
    // },
];

export default { marketplaces };
