import functions from "../../helpers/functions";

export default {
    fullSearch,
    availability,
    numberRange,
    dateRange,
    timeLeft,
};

function isMatched(value, condition) {
    if (value) {
        return condition;
    } else return true;
}

function fullSearch({ row, columnId, value } = {}) {
    function replaceUppercaseWithRegExpr(string) {
        return string
            ?.replace(/L/g, "[a-z]")
            ?.replace(/C/g, "[^aeiou]")
            ?.replace(/V/g, "[aeiou]")
            ?.replace(/N/g, "[0-9]")
            ?.replace(/D/g, ".");
    }

    function splitStrings(string) {
        return string
            ?.split(",")
            ?.map((s) => s.trim())
            .filter((s) => s);
    }

    function getMatchedResult(input, compareWith, patternType) {
        let pattern = "";
        let isNot = false;
        let isTLDs = false;
        switch (patternType) {
            case "exactMatch":
                pattern = "^[]$";
                break;

            case "startsWith":
                pattern = "^[]";
                break;
            case "contains":
                pattern = "[]";
                break;
            case "endsWith":
                pattern = "[]$";
                break;

            case "startsNotWith":
                pattern = "^[]";
                isNot = true;
                break;
            case "containsNot":
                pattern = "[]";
                isNot = true;
                break;
            case "endsNotWith":
                pattern = "[]$";
                isNot = true;
                break;

            case "notExactMatch":
                pattern = "^[]$";
                isNot = true;
                break;
            default:
                // for tld selections
                pattern = "[]$";
                compareWith = row.original.tld;
                isTLDs = true;
        }

        function checkMatch(input) {
            let strings = input
                ? splitStrings(replaceUppercaseWithRegExpr(input))
                : input;
            let output = isMatched(
                input,
                strings == false
                    ? ""
                    : strings
                          ?.map((string) => {
                              let regex = `${pattern.replace("[]", string)}`;
                              regex = new RegExp(regex, "g");

                              let isTrue = regex.test(compareWith);
                              return isNot ? !isTrue : isTrue;
                          })
                          .filter((b) => b).length > 0
            );
            return output;
        }

        if (isTLDs) {
            let isAnySelected = false;
            for (let tld in input) {
                if (input[tld]) {
                    isAnySelected = true;

                    if (checkMatch(input[tld])) {
                        return true;
                    }
                }
            }
            return !isAnySelected;
        } else {
            return checkMatch(input);
        }
    }

    let cellValue = row?.getValue(columnId)?.toString()?.toLowerCase()?.trim();

    let isEveryInputFalse = true;
    let isEveryOutputTrue = true;
    for (let key in value) {
        if (
            (typeof value[key] == "string" && value[key]) ||
            (typeof value[key] == "object" &&
                Object.keys(value[key]).filter((tld) => value[key][tld])
                    .length > 0)
        ) {
            isEveryInputFalse = false;

            let isTrue = getMatchedResult(value[key], cellValue, key);

            if (!isTrue) {
                isEveryOutputTrue = false;
                break;
            }
        }
    }

    return isEveryInputFalse || isEveryOutputTrue;
}

function availability({ row, columnId, value }) {
    let cellValue = row?.getValue(columnId);

    value = value?.selections;

    let isEveryInputFalse = true;
    let isAnyOutputTrue = false;
    for (let label in value) {
        if (value[label]) {
            isEveryInputFalse = false;

            if (cellValue == value[label]) {
                isAnyOutputTrue = true;
            }
        }
    }

    return isEveryInputFalse || isAnyOutputTrue;
}

function numberRange({ row, columnId, value }) {
    let { min, max } = value;

    let cellValue = row?.getValue(columnId)?.toString()?.toLowerCase()?.trim();

    try {
        cellValue = parseFloat(cellValue);

        return (
            (min ? cellValue >= min : true) && (max ? cellValue <= max : true)
        );
    } catch (e) {
        return false;
    }
}

function timeLeft({ row, columnId, value }) {
    // Early return if value or timeLeft is not provided
    if (!value || !value.timeLeft || !value?.timeLeft?.numeric) return true;

    const pastDate = value?.timeLeft?.numeric;
    const cellValue = row?.getValue(columnId)?.toString()?.toLowerCase()?.trim() || "";

    // Check if the date in the cell is more recent or the same as the calculated date
    return pastDate >= cellValue;
}

function dateRange({ row, columnId, value }) {}
