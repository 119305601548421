import { useEffect, useRef, useState } from "react";
import dbConstants from "./dbConstants";
import dbFunc from "./dbFunc";
import Papa from "papaparse";
import axios from "axios";
import functions from "./functions";

export default {
    useGetData,
};

function useGetData({ objectStore, data: data_props = [] }) {
    const [data, setData] = useState([]);
    const isLoading = useRef(true);
    const isMounted = useRef();
    const totalCompleted = useRef(0);
    const requstData = useRef([]);

    useEffect(() => {
        async function run({ url, getNeededData, idx }) {
            // NOTE: Only use this when migrating to new version of DB only
            // await dbFunc.deleteAllDBExcept(dbConstants.DB_NAME);

            await dbFunc.initDB();
            let allData = await dbFunc.getAllData(objectStore);
            let isDataOlderThanADay =
                functions.isLastUpdatedOlderThanADay_ObjectStore(
                    objectStore.objectStoreName
                );

            if (allData.length == 0 || isDataOlderThanADay) {
                axios
                    .get(`https://corsproxy.io/?${url}`)
                    .then(async (res) => {
                        // Parse CSV data
                        Papa.parse(res.data, {
                            header: true,

                            complete: async (results) => {
                                isLoading.current = false;

                                console.log("start time...");
                                let startTime = new Date();

                                console.log(results.data);

                                let data = [];
                                results.data.map((o, itemIdx) => {
                                    if (
                                        Object.keys(o).filter((key) => o[key])
                                            .length > 0 // to not include the last empty element
                                    ) {
                                        data.push(
                                            getNeededData(o, idx, itemIdx)
                                        );
                                    }
                                });

                                totalCompleted.current++;
                                requstData.current =
                                    requstData.current.concat(data);

                                if (
                                    totalCompleted.current == data_props.length
                                ) {
                                    console.log("Im last...", idx);
                                    setData(requstData.current);
                                } else {
                                    console.log("done...", idx);
                                }

                                console.log(
                                    "to load in UI: ",
                                    (new Date() - startTime) / 1000
                                );

                                await dbFunc.addData(data, objectStore);

                                // update the "Last Update" timestamp
                                functions.updateLastUpdated_ObjectStore(
                                    objectStore.objectStoreName
                                );

                                console.log(
                                    "total time",
                                    (new Date() - startTime) / 1000
                                );
                            },
                        });
                    })
                    .catch((err) => {});
            } else {
                isLoading.current = false;

                allData = allData.filter((o) => {
                    return (
                        functions.getTimeLeft({ endTime: o.timeLeft }) !=
                        "Ended"
                    );
                });
                setData(allData);

                dbFunc.removeEndedSales(objectStore, "timeLeft");
            }
        }

        async function runAll() {
            try {
                await Promise.all(
                    data_props.map((o, idx) => run({ ...o, idx }))
                );
            } catch (error) {
                console.error("Error occurred while running tasks:", error);
            }
        }

        if (!isMounted.current) {
            isMounted.current = true;

            runAll();
        }
    }, []);

    return {
        data,
        isLoading,
    };
}
