import styles from "./Dynadot.module.scss";
import functions from "../../helpers/functions";
import IdealTable from "../../components/table/ideal/IdealTable";
import Countdown from "../../components/countdown/Countdown";
import hooks from "../../helpers/hooks";
import dbConstants from "../../helpers/dbConstants";
import tableFunc from "../../helpers/tableFunc";

const objectStore = dbConstants.DYNADOT_OBJECT_STORE;

export default function Dynadot() {
    function getData(o, idx, itemIdx, type) {
        let obj = {};
        for (let key in o) {
            let key_updated = key.toLowerCase().trim();

            switch (key_updated) {
                case "time left":
                    let matches = o[key].match(
                        /([0-9]+ day)|([0-9]+ hour)|([0-9]+ min)|([0-9]+ sec)/g
                    );
                    if (matches?.length > 0) {
                        let times = {};
                        matches.map((timeString) => {
                            let sections = timeString.split(" ");
                            let value = parseInt(sections[0]);
                            let unit = sections[1];

                            switch (unit) {
                                case "day":
                                    times.days = value;
                                    break;
                                case "hour":
                                    times.hours = value;
                                    break;
                                case "min":
                                    times.minutes = value;
                                    break;
                                case "sec":
                                    times.seconds = value;
                                    break;
                            }
                        });

                        // convert to millisec from UNIX time
                        obj.timeLeft = functions.calculateNewFutureDate(times);
                    }
                    break;
                case "end timestamp":
                    obj.timeLeft = parseInt(o[key]);
                    break;
                case "end time":
                    obj.timeLeft = functions.calculateNewFutureDate(o[key]);
                    break;
                case "price":
                case "current price":
                case "bid price":
                    obj.price = functions.getPriceValueFromString(o[key]);
                    break;
                case "starting price":
                    obj.startingPrice = functions.getPriceValueFromString(
                        o[key]
                    );
                    break;
                case "revenue":
                    obj.revenue = functions.getPriceValueFromString(o[key]);
                    break;
                case "visitors":
                    obj.visitors = functions.getPriceValueFromString(o[key]);
                    break;
                case "estibot appraisal":
                case "estibot":
                    obj.appraisal = functions.getPriceValueFromString(o[key]);
                    break;
                case "links":
                    obj.links = functions.getPriceValueFromString(o[key]);
                    break;
                case "age":
                    obj.age = functions.getPriceValueFromString(o[key]);
                    break;
                case "bids":
                    obj.bids = functions.getPriceValueFromString(o[key]);
                    break;
                case "renewal price":
                    obj.renewalPrice = functions.getPriceValueFromString(
                        o[key]
                    );
                    break;
            }
        }

        let domain = o["Domain Name"] ? o["Domain Name"] : o["Domain"];

        return {
            [objectStore.keyPath]: `${idx}_${itemIdx}`,
            ...(domain ? functions.splitDomainTld(domain) : {}),
            ...obj,
            type,
        };
    }

    let { data, isLoading } = hooks.useGetData({
        objectStore,
        data: [
            {
                // Expired Closeout
                url: "https://www.dynadot.com/market/expired-closeout/closeout.csv?keyword=&order_by=10&timezone=PST",
                getNeededData: (o, idx, itemIdx) => {
                    return getData(o, idx, itemIdx, "ec");
                },
            },
            {
                // Expired Auction
                url: "https://www.dynadot.com/market/auction/auctions.csv?keyword=&order_by=16&timezone=PST",
                getNeededData: (o, idx, itemIdx) => {
                    return getData(o, idx, itemIdx, "ea");
                },
            },
            {
                // User Auction
                url: "https://www.dynadot.com/market/user-auction/user_auctions.csv?timezone=PST",
                getNeededData: (o, idx, itemIdx) => {
                    return getData(o, idx, itemIdx, "ua");
                },
            },
            {
                // Backorder Auction
                url: "https://www.dynadot.com/market/backorder-auction/backorder_auction.csv?timezone=PST",
                getNeededData: (o, idx, itemIdx) => {
                    return getData(o, idx, itemIdx, "ba");
                },
            },
            {
                // Last Chance Auction
                url: "https://www.dynadot.com/market/last-chance-auction/lastchance_auction.csv?keyword=&order_by=27&timezone=PST",
                getNeededData: (o, idx, itemIdx) => {
                    return getData(o, idx, itemIdx, "lca");
                },
            },
            {
                // Registry Expired Auction
                url: "https://www.dynadot.com/market/search/aftermarketSearch.csv?keyword=&registry_expired_auctions=1&order_by=31%2C3&timezone=PST",
                getNeededData: (o, idx, itemIdx) => {
                    return getData(o, idx, itemIdx, "rea");
                },
            },
            // Entire List of domains
            // {
            //     url: "https://www.dynadot.com/market/search/aftermarketSearch.csv?keyword=&expired_auctions=1&user_auctions=1&backorder_auctions=1&last_chance_auctions=1&closeout=1&registry_expired_auctions=1&order_by=31%2C3&timezone=PST",
            //     getNeededData: (o, idx) => {
            //         let obj ={};
            //         for (let key in o) {
            //             let key_updated = key.toLowerCase().trim();

            //             switch (key_updated) {
            //                 case "type": obj.type = o[key]; break;
            //                 case "time left":
            //                     let matches = o[key].match(/([0-9]+ day)|([0-9]+ hour)|([0-9]+ min)|([0-9]+ sec)/g);
            //                     if (matches?.length > 0) {
            //                         let times = {};
            //                         matches.map(timeString => {
            //                             let sections = timeString.split(" ");
            //                             let value = parseInt(sections[0]);
            //                             let unit = sections[1];

            //                             switch (unit) {
            //                                 case "day": times.days = value; break;
            //                                 case "hour": times.hours = value; break;
            //                                 case "min": times.minutes = value; break;
            //                                 case "sec": times.seconds = value; break;
            //                             }

            //                         });

            //                         // convert to millisec from UNIX time
            //                         obj.timeLeft = functions.calculateNewFutureDate()
            //                     }
            //                     break;
            //                 case "current price":
            //                     let price = o[key]?.replace("$", "");
            //                     if (price) price = parseFloat(price);
            //                     obj.price = price;
            //                     break;
            //                 case "bids":
            //                     if (obj?.type.toLowerCase() != "closeout") {
            //                         obj.bids = o[key]? parseInt(o[key]):0;
            //                     }
            //                     break;
            //             }
            //         }

            //         return ({
            //             [objectStore.keyPath]: idx,
            //             domain: o["Domain Name"].split(".")[0],
            //             tld: o["Domain Name"].split(".").slice(1).join("."),
            //             ...obj
            //         })
            //     },
            // }
        ],
    });

    return (
        <div className={styles.page}>
            {/* Title */}
            <h1>Dynadot Aftermarket</h1>

            <IdealTable
                isLoading={isLoading.current}
                columns={[
                    tableFunc.getColumn_domain(),
                    tableFunc.getColumn_availability({
                        key: "type",
                        logic: (value) => {
                            switch (value) {
                                case "ec":
                                    value = "Expired Closeout";
                                    break;
                                case "ea":
                                    value = "Expired Auction";
                                    break;
                                case "ua":
                                    value = "User Auction";
                                    break;
                                case "ba":
                                    value = "Backorder Auction";
                                    break;
                                case "lca":
                                    value = "Last Chance Auction";
                                    break;
                                case "rea":
                                    value = "Registry Expired Auction";
                                    break;
                                default:
                                    value = "-";
                            }
                            return value;
                        },
                    }),
                    tableFunc.getColumn_timeLeft(),
                    tableFunc.getColumn_price("price"),
                    tableFunc.getColumn_price("startingPrice"),
                    tableFunc.getColumn_price("appraisal"),

                    tableFunc.getColumn_number("links"),
                    tableFunc.getColumn_number("bids"),

                    tableFunc.getColumn_price("renewalPrice"),

                    tableFunc.getColumn_number("visitors"),
                    tableFunc.getColumn_number("revenue"),
                ]}
                initData={data.map((o) => ({
                    ...o,
                    status: o.type == "ec" ? "buy now" : "bid",
                }))}
            />
        </div>
    );
}
