import { useState } from "react";
import Modal from "../modal/Modal";
import styles from "./QuickView.module.scss";

export default function QuickView({
    customTrigger,
    sideTriggerDisplay,
    header,
    body,
    onClear,
    onApply,
    isLoadingOnApply,
}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => setModalIsOpen(false);

    return (
        <div>
            {/* Modal */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                header={header}
                bodyClassName={styles["modal-body"]}
                body={<div className={styles["body-container"]}>{body}</div>}
                clearButton={{ label: "Clear", onClick: onClear }}
                applyButton={{
                    label: "Apply",
                    onClick: () => {
                        onApply();
                        closeModal();
                    },
                    isLoadingOnClick: isLoadingOnApply
                }}
            />

            <div className={styles["trigger-container"]}>
            <div style={{ cursor: "pointer" }} onClick={openModal}>
                {customTrigger ? customTrigger : <button>Quick View</button>}
            </div>
                
                {sideTriggerDisplay}
            </div>
        </div>
    );
}
