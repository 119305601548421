import { openDB } from "idb";
import functions from "./functions";
import dbConstants from "./dbConstants";

export default {
    initDB,
    createObjectStore,
    addData,
    getAllData,
    deleteAllDBExcept,
    removeEndedSales
}

let db;

async function removeEndedSales(os, endTimeName) {
     // Remove ended sales
     const allData = await getAllData(os);

     addData(allData.filter(o => functions.getTimeLeft({
         endTime: o[endTimeName],
     }) != "Ended"), os);
}

async function initDB() {
    if (db) return;
    db = await openDB(dbConstants.DB_NAME, 1, {
        upgrade(db) {
            // get all the object stores to be created, if not yet
            let stores = dbConstants.stores;
            stores.map(os => {
                createObjectStore(db, os);
            })
        }
    });
}

function createObjectStore(db, os) {
    if (!db.objectStoreNames.contains(os.objectStoreName)) {
        db.createObjectStore(os.objectStoreName, { keyPath: os.keyPath });
    }
}

async function addData(dataArray, os) {
    const tx = db.transaction(os.objectStoreName, 'readwrite');
    const objectStore = tx.objectStore(os.objectStoreName);

    for (const item of dataArray) {
        if (item[os.keyPath]) {
            await objectStore.put(item);
        }
    }

    await tx.done; // Wait for the transaction to complete
    // console.log('All data added successfully');
}

async function getAllData(os) {
    return await db.getAll(os.objectStoreName);
    try {
        // Start a transaction and get the object store
        const transaction = os.transaction(os.objectStoreName, 'readonly');
        const objectStore = transaction.objectStore(os.objectStoreName);

        // Use getAll() to retrieve all records
        const request = objectStore.getAll();
        console.log("2222")

        // Handle success
        request.onsuccess = () => {
        console.log("2222.55555")

            const results = request.result; // This will be an empty array if no records are found
            if (results.length === 0) {
                return [];
                // console.log('No records found.');
            } else {
                return results;
                // console.log('Records retrieved:', results);
            }
        };
        console.log("2222.77777")


        // Handle potential errors
        request.onerror = (event) => {
            console.error('Error retrieving data:', event.target.error);
        };

        // Handle transaction errors
        transaction.onabort = (event) => {
            console.error('Transaction aborted:', event.target.error);
        };

        transaction.onerror = (event) => {
            console.error('Transaction error:', event.target.error);
        };

    } catch (error) {
        // Catch any unexpected errors
        console.error('Unexpected error occurred:', error);
    }
}

// Function to delete all databases except the specified one
async function deleteAllDBExcept(specifiedDBName) {
    try {
        // Get the list of all databases
        const databases = await indexedDB.databases();

        // Loop through the list of databases
        for (const db of databases) {
            const dbName = db.name;
            // Check if the current database is not the specified one
            if (dbName !== specifiedDBName) {
                // Delete the database
                await indexedDB.deleteDatabase(dbName);
                // console.log(`Deleted database: ${dbName}`);
            } else {
                // console.log(`Retained database: ${dbName}`);
            }
        }
    } catch (error) {
        // console.error("Error accessing databases:", error);
    }
}