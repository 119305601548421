import React, { useState } from "react";
import styles from "./Login.module.scss";

const Login = () => {
    const [pageType, setPageType] = useState("login");

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [pwd, setPwd] = useState("");
    const [confirmPwd, setConfirmPwd] = useState("");

    const handleLogin = (event) => {
        event.preventDefault();
        // Handle login logic here
    };

    function handleForgotPwd() {}

    function handleSignUp() {}

    let display = "";
    switch (pageType) {
        case "login":
            display = (
                <>
                    <h1>Log In</h1>
                    <form className={styles.loginForm} onSubmit={handleLogin}>
                        <div className={styles.inputField}>
                            <input
                                type="text"
                                placeholder="Email"
                                className={styles.input}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className={styles.pwdInputField}>
                            <input
                                type="password"
                                placeholder="Password"
                                className={styles.input}
                                value={pwd}
                                onChange={(e) => setPwd(e.target.value)}
                                required
                            />
                        </div>
                        <div
                            className={styles["forgot-pwd"]}
                            onClick={() => setPageType("forgotPwd")}
                        >
                            <span>Forgot Password?</span>
                        </div>
                        <button type="submit" className={styles.button}>
                            Login
                        </button>

                        <span className={styles["sign-up-note-question"]}>
                            Don't have an account?
                        </span>
                        <span
                            className={styles["sign-up-note-answer"]}
                            onClick={() => {
                                setPageType("signUp");
                            }}
                        >
                            Sign Up
                        </span>
                    </form>
                </>
            );
            break;
        case "signUp":
            display = (
                <>
                    <h1>Sign Up</h1>
                    <form className={styles.loginForm} onSubmit={handleSignUp}>
                        <div className={styles.inputField}>
                            <input
                                type="email"
                                placeholder="Email"
                                className={styles.input}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className={styles.inputField}>
                            <input
                                type="text"
                                placeholder="First Name"
                                className={styles.input}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                            />
                        </div>
                        <div className={styles.inputField}>
                            <input
                                type="text"
                                placeholder="Last Name"
                                className={styles.input}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                            />
                        </div>
                        <div className={styles.inputField}>
                            <input
                                type="password"
                                placeholder="Password"
                                className={styles.input}
                                value={pwd}
                                onChange={(e) => setPwd(e.target.value)}
                                required
                            />
                        </div>
                        <div className={styles.inputField}>
                            <input
                                type="password"
                                placeholder="Confirm Password"
                                className={styles.input}
                                value={confirmPwd}
                                onChange={(e) => setConfirmPwd(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className={styles.button}>
                            Sign Up
                        </button>

                        <span className={styles["sign-up-note-question"]}>
                            Already have an account?
                        </span>
                        <span
                            className={styles["sign-up-note-answer"]}
                            onClick={() => {
                                setPageType("login");
                            }}
                        >
                            Log in
                        </span>
                    </form>
                </>
            );
            break;

        case "forgotPwd":
            display = (
                <>
                    <h1>Forgot Password</h1>
                    <form
                        className={styles.loginForm}
                        onSubmit={handleForgotPwd}
                    >
                        <div className={styles.inputField}>
                            <input
                                type="email"
                                placeholder="Email"
                                className={styles.input}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>

                        <button type="submit" className={styles.button}>
                            Reset Password
                        </button>

                        <span
                            className={styles["sign-up-note-answer"]}
                            onClick={() => {
                                setPageType("login");
                            }}
                        >
                            Return to Login
                        </span>
                    </form>
                </>
            );
            break;
    }

    return <div className={styles.loginContainer}>{display}</div>;
};

export default Login;
