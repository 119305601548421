import Countdown from "../components/countdown/Countdown";
import functions from "./functions";

export default {
    getColumn_domain,
    getColumn_timeLeft,
    getColumn_expirationDate,
    getColumn_price,
    getColumn_number,
    getColumn_availability,
};

function getColumn_domain() {
    return {
        ...getKeyHeader("domain"),
        cell: (info) => {
            let domainName = getValue(info);
            let tld = info?.row?.original?.tld;
            return domainName + (tld ? "." + tld : "");
        },
        filterFn: "fullSearch",
    };
}

function getColumn_timeLeft() {
    return {
        ...getKeyHeader("timeLeft"),
        cell: (info) => {
            let value = getValue(info);
            return <Countdown initialSeconds={value} />;
        },
        filterFn: "timeLeft",
        sortingFn: "dateSort",
        extraProps: {
            filters: {
                disallowedTimes: ["years", "months", "weeks"],
            },
        },
    };
}

function getColumn_expirationDate() {
    return {
        ...getKeyHeader("expirationDate"),
        cell: (info) => {
            let value = info.getValue();
            return value
                ? functions.getTimeLeft({ endTime: value })
                : "-";
        },
        filterFn: "timeLeft",
        sortingFn: "dateSort",
        extraProps: {
            filters: {
                disallowedTimes: ["days", "hours", "minutes"],
            },
        },
    }
}

function getColumn_price(key = "price", header = "") {
    return {
        accessorKey: key,
        header: `${header ? header : functions.camelCaseToTitleCase(key)} ($)`,
        cell: (info) => {
            let value = getValue(info);
            return value == "-"? value:functions.addCommasToPrice(value);
        },
        filterFn: "numberRange",
        sortingFn: "numberSort",
    };
}

function getColumn_number(key, header) {
    return {
        ...getKeyHeader(key, header),
        cell: (info) => {
            let value = getValue(info);
            value = value ? value : "0";

            return value;
        },
        filterFn: "numberRange",
        sortingFn: "numberSort",
    };
}

function getColumn_availability({key, header, logic }) {
    return {
        ...getKeyHeader(key, header),
        cell: (info) => {
            let value = getValue(info);
            return logic? logic(value): value;
        },
        filterFn: "availability",
    }
}

function getKeyHeader(key, header) {
    return {
        accessorKey: key,
        header: header? header: functions.camelCaseToTitleCase(key)
    }
}

function getValue(info) {
    let value = info.getValue();
    return value != undefined && value !== ""? value:"-";
}