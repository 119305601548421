import styles from "./../DomainAppraisal.module.scss";
import functions from "../../helpers/functions";
import IdealTable from "../../components/table/ideal/IdealTable";
import hooks from "../../helpers/hooks";
import dbConstants from "../../helpers/dbConstants";
import tableFunc from "../../helpers/tableFunc";

const objectStore = dbConstants.SAV_OBJECT_STORE;

export default function SavAuction() {
    let { data, isLoading } = hooks.useGetData({
        objectStore,
        data: [
            {
                url: "https://d2yienn5xwenuj.cloudfront.net/sav_active_auctions_export.csv",
                getNeededData: (o) => ({
                    [objectStore.keyPath]: o.auction_id,
                    domain: o.sld,
                    tld: o.tld,
                    type: o.type,
                    timeLeft: functions.getMillisecSinceUNIXEpoch(o.end_time),
                    expirationDate: functions.getMillisecSinceUNIXEpoch(
                        o.expiration_date
                    ),
                    renewalPrice: functions.getPriceValueFromString(o.renewal_price),
                    startingBid: functions.getPriceValueFromString(o.starting_bid),
                    currentBid: functions.getPriceValueFromString(o.current_bid_amount),
                    bidders: functions.getPriceValueFromString(o.bidders),
                    bids: functions.getPriceValueFromString(o.bid_count),
                    minBid: functions.getPriceValueFromString(o.min_next_bid),
                    traffic: functions.getPriceValueFromString(o.traffic),
                    revenue: functions.getPriceValueFromString(o.revenue)
                }),
            },
        ],
    });

    return (
        <div className={styles.page}>
            {/* Title */}
            <h1>Sav Auction</h1>

            <IdealTable
                isLoading={isLoading.current}
                columns={[
                    tableFunc.getColumn_domain(),
                    tableFunc.getColumn_availability({ key: "type"}),
                    tableFunc.getColumn_timeLeft(),
                    tableFunc.getColumn_price("minBid"),
                    tableFunc.getColumn_price("currentBid"),
                    tableFunc.getColumn_number("bidders"),
                    tableFunc.getColumn_number("bids"),
                    tableFunc.getColumn_expirationDate(),
                    tableFunc.getColumn_price("startingBid"),
                    tableFunc.getColumn_price("renewalPrice"),
                    tableFunc.getColumn_number("traffic"),
                    tableFunc.getColumn_number("revenue"),
                ]}
                initData={data.map((o) => ({ ...o, status: "bid" }))}
            />
        </div>
    );
}
