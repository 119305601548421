import { useState } from "react";
import styles from "./TextInput.module.scss";
import infoIcon from "../../images/info-icon.svg";
import { Tooltip } from "react-tooltip";

export default function TextInput({
    placeholder,
    onChange = () => {},
    isInfoIconVisible,
    tooltipContent,
    type = "text",
    inputClassName,
    value = ""
}) {
    return (
        <div className={styles.container}>
            <input
                placeholder={placeholder}
                className={`${styles.input} ${inputClassName? inputClassName:""} ${
                    isInfoIconVisible ? styles["info-present"] : ""
                }`}
                value={value}
                onChange={(e) => {
                    let newValue = e.target.value;

                    if (type == "number") {
                        if (/[^0-9]/.test(newValue)) {
                            newValue = newValue.substring(0, newValue.length - 1)
                        }
                    }

                    onChange(newValue);
                }}
            />

            {isInfoIconVisible && (
                <img
                data-tooltip-id={styles.info}
                    className={styles.info}
                    src={infoIcon}
                    onClick={() => {}}
                />
            )}

            <Tooltip
                className={styles.tooltip}
                id={styles.info}
                place="top"
                variant="info"
                openOnClick
            >
                {tooltipContent}
            </Tooltip>
        </div>
    );
}
