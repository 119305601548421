import Checkbox from "../checkbox/Checkbox";
import downArrowIcon from "../../images/down-arrow-icon.svg";
import functions from "../../helpers/functions";

export default {
    getCheckboxColumns,
    getDomainActionColumns,
    getExpandablesColumns
}

function getCheckboxColumns(columns_props) {
    columns_props = [
        {
            flexWidth: 1,
            accessorKey: "checkbox",
            header: ({ table }) => (
                <Checkbox
                    {...{
                        isChecked: table.getIsAllRowsSelected(),
                        indeterminate: table.getIsSomeRowsSelected(),
                        onChange: table.getToggleAllRowsSelectedHandler(),
                    }}
                />
            ),
            cell: ({ row, getValue }) => (
                <div
                    style={{
                        // Since rows are flattened by default,
                        // we can use the row.depth property
                        // and paddingLeft to visually indicate the depth
                        // of the row
                        paddingLeft: `${row.depth * 2}rem`,
                    }}
                >
                    <Checkbox
                        {...{
                            checked: row.getIsSelected(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),
            footer: (props) => props.column.id,
        },
    ].concat(columns_props);

    return columns_props;
}

function getDomainActionColumns(columns_props) {
    columns_props = columns_props.concat([
        {
            accessorKey: "domainAction",
            hideSortIcon: true,
            header: ({ table }) => "",
            cell: ({ row }) => {
                let status = row?.original?.status;
                let domain = row?.original?.domain;
                let tld = row?.original?.tld;

                // choose buttons based on status
                let text;
                switch (status) {
                    case "available":
                        text = "Register";
                        break;
                    case "buy now":
                        text = "Buy Now";
                        break;
                    case "bid":
                        text = "Bid";
                        break;
                    default:
                        text = "Make Offer";
                }

                return (
                    <div style={{ padding: "10px" }}>
                        <div
                            style={{
                                // borderRadius: "10px",
                                backgroundColor: "#df9704",
                                padding: "5px 10px",
                                color: "white",
                                fontWeight: "bold",
                                cursor: "pointer",
                                width: "86px",
                            }}
                            onClick={() => {
                                functions.triggerGAEvent({
                                    action: "appraisal_domain_type_" + text.toLowerCase().replace(/ /g, ""),
                                });

                                functions.triggerGAEvent({
                                    action: "appraisal_domain_length_" + domain.length,
                                });

                                functions.triggerGAEvent({
                                    action: "appraisal_domain_tld_" + tld,
                                });

                                functions.openInNewTab(
                                    // `https://tldgen.com/?domain=${
                                    //     domain + "."+tld
                                    // }`
                                    `https://www.dynadot.com/domain/search?domain=${
                                        domain + tld
                                    }`
                                );
                            }}
                        >
                            {text}
                        </div>
                    </div>
                );
            },
        },
    ]);

    return columns_props;
}

function getExpandablesColumns(columns_props) {
    columns_props = [
        {
            accessorKey: "expand",
            hideSortIcon: true,
            size: 50,
            header: ({ table }) => (
                <button
                    {...{
                        onClick: table.getToggleAllRowsExpandedHandler(),
                        style: {
                            cursor: "pointer",
                            background: "transparent",
                            border: "none",
                        },
                    }}
                >
                    {table.getIsAllRowsExpanded() ? (
                        <img
                            style={{ width: "15px", filter: "invert(1)" }}
                            src={downArrowIcon}
                        />
                    ) : (
                        <img
                            style={{
                                transform: "rotate(-90deg)",
                                width: "15px",
                                filter: "invert(1)",
                            }}
                            src={downArrowIcon}
                        />
                    )}
                </button>
            ),
            cell: ({ row }) => (
                <div
                    style={{
                        // Since rows are flattened by default,
                        // we can use the row.depth property
                        // and paddingLeft to visually indicate the depth
                        // of the row
                        paddingLeft: `${row.depth * 2}rem`,
                    }}
                >
                    <div>
                        {row.getCanExpand() && (
                            <button
                                {...{
                                    onClick: row.getToggleExpandedHandler(),
                                    style: {
                                        cursor: "pointer",
                                        background: "transparent",
                                        border: "none",
                                    },
                                }}
                            >
                                {row.getIsExpanded() ? (
                                    <img
                                        style={{ width: "15px" }}
                                        src={downArrowIcon}
                                    />
                                ) : (
                                    <img
                                        style={{
                                            transform: "rotate(-90deg)",
                                            width: "15px",
                                        }}
                                        src={downArrowIcon}
                                    />
                                )}
                            </button>
                        )}
                    </div>
                </div>
            ),
            footer: (props) => props.column.id,
        },
    ].concat(columns_props);

    return columns_props;
}
