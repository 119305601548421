import { useEffect, useState } from "react";
import TextInput from "../textInput/TextInput";
import styles from "./TimeLeftInput.module.scss";
import functions from "../../helpers/functions";

export default function TimeLeftInput({
    value = {},
    isParentControl,
    typesDisallowed = [],
    onChange = () => {},
}) {
    const [years, setYears] = useState(value?.years);
    const [months, setMonths] = useState(value?.months);
    const [weeks, setWeeks] = useState(value?.weeks);
    const [days, setDays] = useState(value?.days);
    const [hours, setHours] = useState(value?.hours);
    const [minutes, setMinutes] = useState(value?.minutes);

    useEffect(() => {
        setYears(value?.years);
        setMonths(value?.months);
        setWeeks(value?.weeks);
        setDays(value?.days);
        setHours(value?.hours);
        setMinutes(value?.minutes);
    }, [value]);

    let display = [
        {
            placeholder: "Years",
            func: setYears,
            type: "years",
            valueState: years,
        },
        {
            placeholder: "Months",
            func: setMonths,
            type: "months",
            valueState: months,
        },
        {
            placeholder: "Weeks",
            func: setWeeks,
            type: "weeks",
            valueState: weeks,
        },
        {
            placeholder: "Days",
            func: setDays,
            type: "days",
            valueState: days,
        },
        {
            placeholder: "Hours",
            func: setHours,
            type: "hours",
            valueState: hours,
        },
        {
            placeholder: "Minutes",
            func: setMinutes,
            type: "minutes",
            valueState: minutes,
        },
    ].map((o, i) => {
        let type = o.type;
        if (typesDisallowed.find((s) => s == type)) {
            return "";
        }

        return (
            <TextInput
                key={i}
                type={"number"}
                inputClassName={styles.input}
                placeholder={o.placeholder}
                value={o?.valueState}
                onChange={(newValue) => {
                    let years_new = type == "years" ? newValue : years;
                    let months_new = type == "months" ? newValue : months;
                    let weeks_new = type == "weeks" ? newValue : weeks;
                    let days_new = type == "days" ? newValue : days;
                    let hours_new = type == "hours" ? newValue : hours;
                    let minutes_new = type == "minutes" ? newValue : minutes;

                    let times = {
                        years: years_new,
                        months: months_new,
                        weeks: weeks_new,
                        days: days_new,
                        hours: hours_new,
                        minutes: minutes_new,
                    };

                    let numeric = functions.calculateNewFutureDate(times);
                    let newTime = JSON.parse(JSON.stringify(times));
                    newTime.numeric = numeric;

                    onChange(newTime);
                    if (!isParentControl) o.func(newValue);
                }}
            />
        );
    });

    return <div className={styles.container}>{display}</div>;
}
