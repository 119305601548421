import { useEffect, useRef, useState } from "react";
import styles from "./Select.module.scss";
import ReactSelect, { components } from "react-select";

export default function Select({ classNames = {}, isFirstOptionUsedAsLabel, isNotSearchable, isNotMulti, selectedOption: selectedOption_prop = [], placeholder, options, onChange = () => {} }) {
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        setSelectedOptions(selectedOption_prop)
    }, [JSON.stringify(selectedOption_prop)])

    const ValueContainer = (props) => {
        let values = props.selectProps.value;

        return (
            <components.ValueContainer
                {...props}
                innerProps={{
                    style: {
                        maxHeight: "5em",
                        overflow: "auto",
                        overflowX: "hidden",
                    },
                    content:
                        values.length == options?.length
                            ? "All"
                            : `${values.length} Selected`,
                }}
            />
        );
    };

    const formatDisplayValue = () => {
        if (selectedOptions.length > 2) {
            return `${selectedOptions.length}+ options selected`;
        }
        return selectedOptions.map((option) => option.label).join(", ");
    };

    const handleChange = (selected) => {
        let newSelections;
        if (Array.isArray(selected)) {
            if (selected.some((option) => option.value === "deselect-all")) {
                newSelections = [];
            } else if (selected.some((option) => option.value === "select-all")) {
                // If "Select All" is selected, select all options
                newSelections = options;
            }
            else {
                // Otherwise, update the selected options normally
                newSelections = selected;
            }
        }
        else {
            // Otherwise, update the selected options normally
            newSelections = selected;
        }

        onChange(newSelections);
        setSelectedOptions(newSelections);
    };

    const allOptions = [
        ...(isNotMulti?[]:(selectedOptions?.length == options?.length
            ? [{ value: "deselect-all", label: "Deselect All" }]
            : [{ value: "select-all", label: "Select All" }])),
        ...(options ? options : []),
    ];

    return (
        <div>
            <ReactSelect
                classNames={{
                    container: (provided) => {
                        return `${classNames?.container? classNames.container:styles.container}`;
                    },
                    control: (provided) => {
                        return `${classNames?.control? classNames.control:styles.control}`;
                    },
                    valueContainer: (provided) => {
                        return `${classNames?.valueContainer? classNames.valueContainer:""}`;
                    },
                    menuList: (provided) => {
                        return `${classNames?.menuList? classNames.menuList:styles["menu-list"]}`;
                    },

                    menu: (provided) => {
                        return `${classNames?.menu? classNames.menu:styles.menu}`;
                    },
                    option: (provided) => {
                        return `${styles.option} ${isFirstOptionUsedAsLabel? styles["first-ele-excluded"]:""}`;
                    },
                    dropdownIndicator: (provided) => {
                        return `${classNames?.["dropdown-indicator"]? classNames["dropdown-indicator"]:styles["dropdown-indicator"]}`;
                    },
                    multiValue: (provided) => {
                        return `${styles["multi-value"]}`;
                    },
                    indicatorSeparator: (provided) => {
                        return classNames.indicatorSeparator? classNames.indicatorSeparator:"";
                    },
                    singleValue: (provided) => {
                        return classNames.singleValue? classNames.singleValue:"";
                    },
                }}
                closeMenuOnSelect={false}
                components={{
                    ValueContainer
                }}
                placeholder={
                    placeholder ? <span>Select {placeholder}</span> : "Select"
                }
                maxMenuHeight={100}
                onChange={(newValue) => {
                    if (!isFirstOptionUsedAsLabel || (isFirstOptionUsedAsLabel &&  allOptions[0].label != newValue.label)) {
                        handleChange(newValue);
                    }
                }}
                value={selectedOptions}
                formatGroupLabel={formatDisplayValue}
                isMulti={!isNotMulti}
                isSearchable={!isNotSearchable}
                options={allOptions}
            />
        </div>
    );
}
