const SAV_OBJECT_STORE = {
    objectStoreName: "Sav_Domains",
    keyPath: "id",
};

const DYNADOT_OBJECT_STORE = {
    objectStoreName: "Dynadot_Domains",
    keyPath: "id",
};

const DROPCATCH_OBJECT_STORE = {
    objectStoreName: "DropCatch_Domains",
    keyPath: "id",
};

const CATCHED_OBJECT_STORE = {
    objectStoreName: "Catched_Domains",
    keyPath: "id",
};

const SEDO_OBJECT_STORE = {
    objectStoreName: "Sedo_Domains",
    keyPath: "id",
};

const NAMECHEAP_OBJECT_STORE = {
    objectStoreName: "NameCheap_Domains",
    keyPath: "id",
};

// https://inventory.auctions.godaddy.com/
const GODADDY_OBJECT_STORE = {
    objectStoreName: "GoDaddy_Domains",
    keyPath: "id",
};

const SEODOMAINS_OBJECT_STORE = {
    objectStoreName: "SEODomains_Domains",
    keyPath: "id",
};

const NAME_OBJECT_STORE = {
    objectStoreName: "Name_Domains",
    keyPath: "id",
};

const DOMAINMARKET_OBJECT_STORE = {
    objectStoreName: "DomainMarket_Domains",
    keyPath: "id",
};
const FLIPPA_OBJECT_STORE = {
    objectStoreName: "Flippa_Domains",
    keyPath: "id",
};

// https://www.epik.com/buy
const EPIK_OBJECT_STORE = {
    objectStoreName: "Epik_Domains",
    keyPath: "id",
};

const NAMEJET_OBJECT_STORE = {
    objectStoreName: "NameJet_Domains",
    keyPath: "id",
};

const SNAPNAMES_OBJECT_STORE = {
    objectStoreName: "SnapNames_Domains",
    keyPath: "id",
};

// https://nicsell.com/en/themes
// https://nicsell.com/sitemap.xml
// questionable
const NICSELL_OBJECT_STORE = {
    objectStoreName: "NicSell_Domains",
    keyPath: "id",
};

const NAMESILO_OBJECT_STORE = {
    objectStoreName: "NameSilo_Domains",
    keyPath: "id",
};

// https://domainlore.uk/
// questionable
const DOMAINLORE_OBJECT_STORE = {
    objectStoreName: "DomainLore_Domains",
    keyPath: "id",
};

const EBAY_OBJECT_STORE = {
    objectStoreName: "Ebay_Domains",
    keyPath: "id",
};

const PARKIO_OBJECT_STORE = {
    objectStoreName: "ParkIO_Domains",
    keyPath: "id",
};

const stores = [
    SAV_OBJECT_STORE,
    DYNADOT_OBJECT_STORE,
    // DROPCATCH_OBJECT_STORE,
    // CATCHED_OBJECT_STORE,
    // SEDO_OBJECT_STORE,
    // NAMECHEAP_OBJECT_STORE,
    // GODADDY_OBJECT_STORE,
    // SEODOMAINS_OBJECT_STORE,
    // NAME_OBJECT_STORE,
    // DOMAINMARKET_OBJECT_STORE,
    // FLIPPA_OBJECT_STORE,
    // EPIK_OBJECT_STORE,
    // NAMEJET_OBJECT_STORE,
    // SNAPNAMES_OBJECT_STORE,
    // NICSELL_OBJECT_STORE,
    // NAMESILO_OBJECT_STORE,
    // DOMAINLORE_OBJECT_STORE,
    // EBAY_OBJECT_STORE,
    // PARKIO_OBJECT_STORE,
];

const DB_NAME = "DomainingCentral_DB_v1";

export default {
    DB_NAME,
    stores,
    SAV_OBJECT_STORE,
    DYNADOT_OBJECT_STORE,
    CATCHED_OBJECT_STORE,
    DOMAINLORE_OBJECT_STORE,
    DOMAINMARKET_OBJECT_STORE,
    DROPCATCH_OBJECT_STORE,
    EBAY_OBJECT_STORE,
    EPIK_OBJECT_STORE,
    FLIPPA_OBJECT_STORE,
    GODADDY_OBJECT_STORE,
    NAMECHEAP_OBJECT_STORE,
    NAMEJET_OBJECT_STORE,
    NAMESILO_OBJECT_STORE,
    NAME_OBJECT_STORE,
    NICSELL_OBJECT_STORE,
    PARKIO_OBJECT_STORE,
    SEDO_OBJECT_STORE,
    SEODOMAINS_OBJECT_STORE,
    SNAPNAMES_OBJECT_STORE,
};
