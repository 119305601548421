import React, { useEffect, useState } from 'react';
import functions from '../../helpers/functions';

const Countdown = ({ initialSeconds }) => {
  const [time] = useState(initialSeconds);
  const [isRenderNeeded, setIsRenderNeeded] = useState();

  useEffect(() => {
    if (isRenderNeeded) {
        setIsRenderNeeded(false);
    }
  }, [isRenderNeeded])

  useEffect(() => {
    const interval = setInterval(() => {
        setIsRenderNeeded(true);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <span>{functions.getTimeLeft({ endTime: time })}</span>;
};

export default Countdown;