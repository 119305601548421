import React, { useEffect, useState } from "react";
import styles from "./Checkbox.module.scss";

export default function Checkbox({
    indeterminate,
    onChange = () => {},
    initValue = false,
    label,
    isChecked: isChecked_props
}) {
    const [isChecked, setIsChecked] = useState(initValue);
    const ref = React.useRef(null);

    useEffect(() => {
        if (typeof indeterminate === "boolean") {
            ref.current.indeterminate = !isChecked_props && indeterminate;
        }
    }, [ref, indeterminate]);

    useEffect(() => {
        setIsChecked(isChecked_props);
    }, [isChecked_props])

    const handleChange = (event) => {
        setIsChecked(event.target.checked);
        onChange(event.target.checked);
    };

    return (
        <label className={styles.container}>
            <input
                ref={ref}
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
            />
            <span>{label}</span>
        </label>
    );
}
