"use client";

import { useState, useEffect, useRef } from "react";
import styles from "./DomainAppraisal.module.scss";
import { useLocation } from "react-router-dom";
import functions from "../helpers/functions";
import IdealTable from "../components/table/ideal/IdealTable";

const TYPE_EXTENSION = "extension";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

let timeoutId;

export default function DomainAppraisal() {
    const query = useQuery();
    const type = query.get("type");

    const [data, setData] = useState([]);
    const [totalRenders, setTotalRenders] = useState(0);
    const currentAdsPos = useRef();

    const totalRendersRef = useRef(0);
    const isExtensionDataDoneLoading = useRef();

    const [isRenderNeeded, setIsRenderNeeded] = useState(true);

    useEffect(() => {
        if (isRenderNeeded) {
            setIsRenderNeeded(false);

            const msg = document.querySelectorAll(`.${styles["ads-msg"]}`);
            msg.forEach((ele) => {
                if (ele) ele.classList.add(styles["animation"]);
            });

            const header = document.querySelectorAll(`.${styles["ads-header"]}`);
            header.forEach((ele) => {
                if (ele) ele.classList.add(styles["animation"]);
            });

            const action = document.querySelectorAll(`.${styles["ads-action"]}`);
            action.forEach((ele) => {
                if (ele) ele.classList.add(styles["animation"]);
            });

            const logo = document.querySelectorAll(`.${styles["ads-logo"]}`);
            logo.forEach((ele) => {
                if (ele) ele.classList.add(styles["animation"]);
            });
        }
    }, [isRenderNeeded]);

    let affiliateCode = "?aff=d8c9fa07-b089-4365-b9fb-a5b24e733a6c";
    const adsData = [
        {
            header: "Website Builder",
            type: "wb",
            msg: "Create a beautiful website exactly the way you want it",
            url:
                "https://www.clickfunnels.com/features/websites" +
                affiliateCode,
        },
        {
            header: "Drag 'n Drop Editor",
            type: "dnde",
            msg: "Easy and powerful drag and drop page editing experience",
            url:
                "https://www.clickfunnels.com/features/clickfunnels-editor" +
                affiliateCode,
        },
        {
            header: "Blogging",
            type: "b",
            msg: "Create a blog that positions you an expert in your industry",
            url: "https://www.clickfunnels.com/features/blog" + affiliateCode,
        },
        {
            header: "Landing Pages",
            type: "lp",
            msg: "Create landing pages for your website or your funnels",
            url:
                "https://www.clickfunnels.com/features/landing-pages" +
                affiliateCode,
        },
        {
            header: "Sales Funnels",
            type: "sf",
            msg: "Convert your online visitors into actual paying customers",
            url:
                "https://www.clickfunnels.com/features/sales-funnels" +
                affiliateCode,
        },
    ];

    useEffect(() => {
        if (currentAdsPos.current == undefined) {
            currentAdsPos.current = 0;

            setInterval(() => {
                currentAdsPos.current =
                    (currentAdsPos.current + 1) % adsData.length;
                setIsRenderNeeded(true);

                const msg = document.querySelectorAll(`.${styles["ads-msg"]}`);
                msg.forEach((ele) => {
                    if (ele) ele.classList.remove(styles["animation"]);
                });

                const header = document.querySelectorAll(
                    `.${styles["ads-header"]}`
                );
                header.forEach((ele) => {
                    if (ele) ele.classList.remove(styles["animation"]);
                });

                const action = document.querySelectorAll(
                    `.${styles["ads-action"]}`
                );
                action.forEach((ele) => {
                    if (ele) ele.classList.remove(styles["animation"]);
                });

                const logo = document.querySelectorAll(`.${styles["ads-logo"]}`);
                logo.forEach((ele) => {
                    if (ele) ele.classList.remove(styles["animation"]);
                });
            }, 10000);
        }
    }, []);

    function formatData(data) {
        let updatedData = data.map((o) => ({
            domain: o.domain?.split(".")[0],
            tld: o.domain?.split(".").slice(1).join("."),
            tool: o.tool,
            value: o.value ? o.value : 0,
            price: o.price ? o.price : 0,
            status: o?.status ? o.status : "taken",
            subrowHeader: o?.comparableSales ? "Comparable Sales" : undefined,
            subRows: o?.comparableSales?.map((o) => ({
                tool: "GoDaddy",
                domain: o.domain?.split(".")[0],
                tld: o.domain?.split(".").slice(1).join("."),
                value: o.value ? o.value : 0,
                price: o.price ? o.price : 0,
                status: o?.status ? o.status : "taken",
            })),
        }));

        return updatedData;
    }

    function sortData(data) {
        data.sort((a, b) => {
            if (a.value > b.value) return -1;
            if (a.value < b.value) return 1;
            return 0;
        });
        return data;
    }

    useEffect(() => {
        let ele = document.getElementById("extension-data");

        if (ele && type == TYPE_EXTENSION) {
            let valueString = ele.value;

            if (valueString) {
                try {
                    let value = JSON.parse(valueString);
                    ele.remove();

                    value = formatData(value);
                    value = sortData(value);

                    setData(value);
                    isExtensionDataDoneLoading.current = true;
                } catch (e) {}
            } else {
                totalRendersRef.current++;
                clearTimeout(timeoutId); // Clear any existing timeout

                // Set a new timeout
                timeoutId = setTimeout(() => {
                    setTotalRenders(totalRendersRef.current);
                }, 500);
            }
        }
    }, [totalRenders]);

    if (type == TYPE_EXTENSION) {
        return (
            <div className={styles.page}>
                {/* Extension JSON Data */}
                <input
                    style={{ display: "none" }}
                    id={"extension-data"}
                ></input>

                {/* Title */}
                <h1>Domain Appraisal</h1>

                <div className={styles["container"]}>
                    {/* Horizontal Advertisement */}
                    <div
                        className={styles["horizontal-ads"]}
                        onClick={() => {
                            functions.triggerGAEvent({
                                action: "appraisal_ads_" + adsData[currentAdsPos.current]?.type,
                            });
                            
                            window.open(
                                adsData[currentAdsPos.current]?.url,
                                "_blank"
                            );
                        }}
                    >
                        {/* Logo */}
                        <img
                            className={styles["ads-logo"]}
                            src={
                                "https://i.ibb.co/zfT1xbw/Click-Funnels-Logo.png"
                            }
                        />

                        <div className={styles["ads-text-wrapper"]}>
                            {/* Header */}
                            <div className={styles["ads-header"]}>
                                {adsData[currentAdsPos.current]?.header}
                            </div>

                            {/* Message */}
                            <div
                                className={`${styles["ads-msg"]} ${styles["animation"]}`}
                            >
                                {adsData[currentAdsPos.current]?.msg}
                            </div>
                        </div>

                        {/* Action */}
                        <div className={styles["ads-action"]}>Try for Free</div>
                    </div>

                    <IdealTable
                        isLoading={!isExtensionDataDoneLoading.current}
                        isExpandable
                        columns={[
                            {
                                accessorKey: "domain",
                                header: "Domain",
                                size: 300,
                                cellStyle: { flex: "1" },
                                cell: (info) => {
                                    let domainName = info.getValue();
                                    let tld = info?.row?.original?.tld;
                                    return domainName + (tld ? "." + tld : "");
                                },
                                filterFn: "fullSearch",
                            },
                            {
                                accessorKey: "value",
                                header: `Appraisal ($)`,
                                cell: (info) => {
                                    let value = info.getValue();

                                    return value >= 0
                                        ? functions.addCommasToPrice(value)
                                        : value == 0
                                        ? 0
                                        : "-";
                                },
                                filterFn: "numberRange",
                                sortingFn: "numberSort",
                            },
                            {
                                accessorKey: "tool",
                                header: "Tool",
                                cell: (info) => {
                                    let value = info.getValue();
                                    value = value ? value : "-";
                                    return value;
                                },
                                filterFn: "availability",
                            },
                            {
                                accessorKey: "status",
                                header: `Status`,
                                cell: (info) => {
                                    let status = info.getValue();
                                    status = functions.toTitleCase(status);
                                    return status;
                                },
                                filterFn: "availability",
                            },
                            {
                                accessorKey: "price",
                                header: `Price ($)`,
                                cell: (info) => {
                                    let value = info.getValue();
                                    if (value > 0) {
                                        value += "";
                                        let sections = value.split(".");
                                        let dollars =
                                            functions.addCommasToPrice(
                                                sections[0]
                                            );
                                        return dollars;
                                    }
                                    return "-";
                                },
                                filterFn: "numberRange",
                                sortingFn: "numberSort",
                            },
                        ]}
                        initData={data}
                        customStyle={{
                            maxWidth:
                                window.innerWidth > 768
                                    ? "calc(100vw - 320px)"
                                    : "calc(100vw - 40px)",
                        }}
                    />

                    {/* Vertical Advertisement */}
                    <div
                        className={styles["vertical-ads"]}
                        onClick={() => {
                            functions.triggerGAEvent({
                                action: "appraisal_ads_" + adsData[currentAdsPos.current]?.type,
                            });

                            window.open(
                                adsData[currentAdsPos.current]?.url,
                                "_blank"
                            );
                        }}
                    >
                        {/* Logo */}
                        <img
                            className={styles["ads-logo"]}
                            src={
                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWAQ2731Yr-Dphr9HVwJZGN_zricFuYNiiWQ&s"
                            }
                        />

                        {/* Header */}
                        <div className={styles["ads-header"]}>
                            {adsData[currentAdsPos.current]?.header}
                        </div>

                        {/* Message */}
                        <div
                            className={`${styles["ads-msg"]} ${styles["animation"]}`}
                        >
                            {adsData[currentAdsPos.current]?.msg}
                        </div>

                        {/* Action */}
                        <div className={styles["ads-action"]}>Try for Free</div>
                    </div>
                </div>
            </div>
        );
    } else {
        return "";
    }
}
