import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styles from './Modal.module.scss';
import { ThreeDots } from "react-loader-spinner";

ReactModal.setAppElement('#root'); // Set the app element for accessibility

const Modal = ({ isOpen, onRequestClose, header, body, bodyClassName, clearButton, applyButton }) => {
  const [isApplying, setIsApplying] = useState();

  useEffect(() => {
    if (isApplying) setIsApplying(false);
}, [isApplying]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.header}>
        <h2>{header}</h2>
        <button className={styles.closeButton} onClick={onRequestClose}>
          &times;
        </button>
      </div>
      <div className={`${styles.body} ${bodyClassName? bodyClassName:""}`}>
        {body}
      </div>
      <div className={styles.footer}>
      {clearButton && (
          <button className={styles.clearButton} onClick={clearButton.onClick}>
            {clearButton.label}
          </button>
        )}

        {applyButton && (
          <button className={styles.applyButton} onClick={(e) => {
            applyButton.onClick(e);
            if (applyButton.isLoadingOnClick) setIsApplying(true);
          }}>
            {applyButton.label} {
            applyButton.isLoading 
            && 
            (
              <ThreeDots
              visible={true}
              height="17"
              width="17"
              color="#ffffff"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{ marginLeft: "10px"}}
              wrapperClass=""
              />
            )}
          </button>
        )}
      </div>
    </ReactModal>
  );
};

export default Modal;