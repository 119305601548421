export default {
    dateSort,
    numberSort
};

function dateSort(rowA, rowB, columnId, direction) {
    const dateA = new Date(rowA.getValue(columnId));
    const dateB = new Date(rowB.getValue(columnId));

    return direction === "desc" ? dateB - dateA : dateA - dateB; // Sort based on direction
}

function numberSort(rowA, rowB, columnId, direction) {
    const valueA = parseFloat(rowA.getValue(columnId));
    const valueB = parseFloat(rowB.getValue(columnId));

    // Handle NaN cases gracefully
    if (isNaN(valueA) && isNaN(valueB)) return 0; // Both are NaN
    if (isNaN(valueA)) return direction === 'asc' ? 1 : -1; // valueA is NaN
    if (isNaN(valueB)) return direction === 'asc' ? -1 : 1; // valueB is NaN

    return direction === 'desc' ? valueB - valueA : valueA - valueB; // Sort based on direction
  }
